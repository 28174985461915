.loginInputTpass .p-inputtext {
    background-color: #000000 !important;
    background:#000000;
    border-width:0;
    align-self: center;
    text-align: center;
    width:300px;
    color: #ffffff;
}

.modalAdd .p-dialog .p-dialog-header{
    background-color: #000000 !important;
}
