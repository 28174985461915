.p-datepicker{
    background-color: #14161b !important;
    color: #ffffff !important;
}

.p-datepicker .p-datepicker-header {
    background-color: #14161b !important;
    color: #ffffff !important;
}

.p-inputtext{
    background-color: #14161b !important;
    color: #ffffff !important;
}