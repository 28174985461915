.loginInputTrade .p-inputtext {

    background-color: #000000 !important;
    background:#000000;
    border-width:0;
    align-self: center;
    text-align: center;
    width:200px;
    color: #ffffff;
}

.loginInputSearch .p-inputtext {
    background-color: #14161b !important;
    background:#14161b;
    border-width:0;
    align-self: center;
    text-align: center;
    width:300px;
    color: #ffffff;
}

