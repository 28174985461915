.loginInputWatclist .p-inputtext {
    background-color: #14161b !important;
    background:#14161b;
    border-width:0;
    align-self: center;
    text-align: center;
    width:300px;
    color: #ffffff;
}







