


.p-inputtext:enabled:focus  {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;

}
.p-inputtext:enabled:focus-visible {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-checkbox .p-checkbox-box{
    background: #000000 !important;
}


.loginview{
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    height: 70%;
    width: 45%;
    background-color: #000000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.loginInputDark .p-inputtext {
    background-color: #14161b !important;
    background:#14161b;
    border-width:0;
    margin-bottom: 20px;
    align-self: center;
    text-align: center;
    width:300px;
    color: #ffffff;
}
.loginbutton{
    width: 250px;
    padding: 10px;
    background-color: #435abd;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}







