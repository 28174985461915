


.p-inputtext:enabled:focus  {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;

}
.p-inputtext:enabled:focus-visible {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.InputDark .p-inputtext {
    background-color: #14161b !important;
    background:#14161b;
    border-width:0;
    align-self: center;
    text-align: center;
    width:140px;
    color: #ffffff;
    height: 40px;
}

 .p-dialog .p-dialog-header {
    background-color: #435abd !important;
}
.p-dialog .p-dialog-header .p-dialog-title {
    color: #ffffff;
}
.p-icon {
    color: #ffffff;
}


.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    background-color: #ea0606 !important;
}

.p-dialog .p-dialog-content {
    background-color: #14161b !important;
}